@import "@/src/styles/common.scss";
.Slider {

  [class^="number-slide"],
  [class*=" number-slide"] {
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    height: 300px;
    max-height: 100vh;
  }
}


.navigation-wrapper {
  position: relative;
  width: 100%;
}

.dots {
  display: flex;
  padding: 20px 0 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #C9C9C9;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #26ABFF;
}

.arrow {
  width: 0.24rem;
  height: 0.24rem;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  color: #fff;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.16);
  cursor: pointer;

  &:hover {
    // background-color: #464c51;
  }
}

.arrow--icon {
  color: #fff;
  font-size: 0.4rem !important;
  font-weight: 800;
}

.arrow--left {
  left: 0.2rem; // pc端最大宽度为1440px; 展示3个时宽度为1185px; 剩余间距为x / 2 = left; 取整
}

.arrow--right {
  left: auto;
  right: 0.2rem;
}

.arrow--disabled {
  fill: #8e9399;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.3);
  visibility: hidden;
}

.slider-wrapper-pc-layout {
  margin-top: 100px !important;
}

.slider-wrapper-pc-layout+.slider-wrapper-pc-layout {
  margin-top: 0 !important;
}

.dots {
  display: flex;
  padding: 20px 0 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #C9C9C9;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot-active {
  background: #26ABFF;
}
